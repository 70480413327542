import React, { useState, useEffect } from "react";
import { useEventID } from "wecall-config-lib";
// import logo from "../assets/logo.png";
// import heroimage from '../assets/c836c363-2bde-11ee-b5e1-06326af95a41_e99f86c93b01379314b774d4475ee07a005911e1_oprah news.jpg';
//import newslogo1 from "../assets/news-logo01.png";
//import newslogo2 from "../assets/news-logo02.png";
//import newslogo3 from "../assets/news-logo03.png";
//import newslogo4 from "../assets/news-logo04.png";
//import newslogo5 from "../assets/news-logo05.png";
//import newslogo6 from "../assets/news-logo06.png";
// import bills from "../assets/004-bill.png";
// import rent from "../assets/001-key.png";
// import gas from "../assets/002-gas-station.png";
// import food from "../assets/003-food.png";
// import dummmyuser from "../assets/Rectangle 729.png";
// import star from "../assets/star.png";
import { Link, Outlet } from "react-router-dom";
// import arrow from "../assets/arrow.svg";
// import testimonial from "../assets/testimonials.png";
// import user1 from "../assets/user-1.jpeg";
// import user2 from "../assets/user-2.jpeg";
// import user3 from "../assets/user-3.jpeg";
// import user4 from "../assets/user-4.jpeg";
import InitialScripts from "../script/initialScripts";
// import FloatingCard from "../floating-card";

const FloatingCard = React.lazy(() => import("../floating-card"));

export default function DefaultPage({ setNumber, cityStateResponse }) {
  const { state, city, zip } = cityStateResponse
  return (
    <>
      <InitialScripts setNumber={setNumber} />
      <div className="App">
        <div className="mainWrapper">
          <header>
            <div className="container">
              <div className="row">
                <div className="logo-wrapper">
                  <a href="#">
                    <img  loading="lazy" src={'/assets/logo.png'} ></img>
                  </a>
                </div>
              </div>
            </div>
          </header>
          <div className="hero-section">
            <div className="container">
              <div className="row">
                <Outlet />
                {
                //<div className="logo-strip">
                //  <div className="logoitem">
                //    <img  loading="lazy"src={newslogo1}></img>
                //    <img  loading="lazy"src={newslogo2}></img>
                //    <img  loading="lazy"src={newslogo3}></img>
                //    <img  loading="lazy"src={newslogo4}></img>
                //    <img  loading="lazy"src={newslogo5}></img>
                //    <img  loading="lazy"src={newslogo6}></img>
                //  </div>
                //</div>
                //
                }
              </div>
            </div>
          </div>
          <div className="section1">
            <div className="container">
              <div className="row">
                <div className="section-1-content">
                  <h1>Check for the New Subsidy for Minimum Wage Americans</h1>
                  <h2>
                    Claim up to $6400 SUBSIDY to pay for all your health care
                    needs.
                  </h2>
                  <h2>Anything left over can be used:</h2>
                  <div className="item-benefits">
                    <ul>
                      <li>
                        <div className="benefit">
                          <img  loading="lazy" src={'/assets/003-food.png'}></img>
                          <p>Groceries</p>
                        </div>
                      </li>
                      <li>
                        <div className="benefit">
                          <img  loading="lazy" src={'/assets/001-key.png'}></img>
                          <p>Rent</p>
                        </div>
                      </li>
                      <li>
                        <div className="benefit">
                          <img  loading="lazy" src={'/assets/002-gas-station.png'}></img>
                          <p>Gas</p>
                        </div>
                      </li>
                      <li>
                        <div className="benefit">
                          <img  loading="lazy" src={'/assets/004-bill.png'}></img>
                          <p>Bills</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <h3>or whatever else you need.</h3>
                  <div className="cta-holder">
                    <Link to={"income"} id="cta-click">
                      I Want My Card <img  loading="lazy" src={'/assets/arrow.svg'} alt=""></img>
                    </Link>
                    <span>37 Available Slots Left, Act Now!</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="testimonial">
            <div className="container">
              <div className="row">
                <div className="testimonial-headline">
                  <h1>Try Yourself Today!</h1>
                  <img  loading="lazy"src={'/assets/testimonials.png'} alt="" />
                </div>
                <div className="testimonial-content-holder">
                  <h3 className="h6">
                    We strive to offer you the best service, as evidenced by our
                    Trustpilot star rating.
                  </h3>
                </div>
                <div className="testimonial-list">
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <div className="testimonial-left">
                          <img  loading="lazy"src={'/assets/user-1.jpeg'}></img>
                        </div>
                        <div className="testimonial-right">
                          <div className="star-rating">
                            <img  loading="lazy" src={'/assets/star.png'} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <h3>Floyd Miles</h3>
                        <p>
                          I just received mine fast! They worked tirelessly to
                          secure the financial subsidy I needed, and their
                          dedication to customer satisfaction is unmatched.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <div className="testimonial-left">
                          <img  loading="lazy"src={'/assets/user-2.jpeg'}></img>
                        </div>
                        <div className="testimonial-right">
                          <div className="star-rating">
                            <img  loading="lazy"src={"/assets/star.png"} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <h3>Michael Anderson</h3>
                        <p>
                          Just in time when i need it the most! I can't thank US
                          Support enough for their prompt assistance. Their
                          team's commitment to me is truly commendable
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <div className="testimonial-left">
                          <img  loading="lazy"src={'/assets/user-4.jpeg'}></img>
                        </div>
                        <div className="testimonial-right">
                          <div className="star-rating">
                            <img  loading="lazy"src={'/assets/star.png'} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <h3>Olivia Martinez</h3>
                        <p>
                          I was in a financial bind, and US Support came to the
                          rescue. Their team's dedication and service to people
                          are top-notch, and I couldn't be happier with their
                          help.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <div className="testimonial-left">
                          <img  loading="lazy"src={'/assets/user-3.jpeg'}></img>
                        </div>
                        <div className="testimonial-right">
                          <div className="star-rating">
                            <img  loading="lazy"src={'/assets/star.png'} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <h3>David Wilson</h3>
                        <p>
                          I'm still in awe of how quickly I received mine. This
                          helps me a lot as a minimum wage earner. Their
                          commitment to help you is evident in every step of the
                          process. Highly recommended!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="container">
              <div className="row"></div>
            </div>
          </div>
        </div>
      </div>
      <React.Suspense fallback={<></>}>
        <FloatingCard />
      </React.Suspense>
      <LanderSocialEventTrigger />
    </>
  );
}

function LanderSocialEventTrigger() {
  const { getEventId } = useEventID();

  const viewContentKey = "PageView";
  const viewContentKey2 = "PageView2";

  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);

  useEffect(() => {
    if (window.fbcFunc) {
      window.fbcFunc("track", "PageView", { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey, "true");
    }

    if (window.tikTokEvent && params.get("utm_source") === "tiktok") {
      window.tikTokEvent.track("ViewContent", {}, { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey2, "true");
    }
  }, []);
  return <></>;
}


