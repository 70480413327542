import React, { useState, useEffect } from "react";
import { useEventID } from "wecall-config-lib";
import logo from "../assets/logo.png";
import heroimage from "../assets/c836c363-2bde-11ee-b5e1-06326af95a41_e99f86c93b01379314b774d4475ee07a005911e1_oprah news.jpg";
import { Link, Outlet, Route, Routes, useLocation } from "react-router-dom";
import arrow from "../assets/arrow.svg";
import testimonial from "../assets/testimonials.png";
// import user1 from '../assets/user-1.jpeg';
// import user2 from '../assets/user-2.jpeg';
// import user3 from '../assets/user-3.jpeg';
// import user4 from '../assets/user-4.jpeg';
import InitialScripts from "../script/initialScripts";
// import FloatingCard from "../floating-card";
import trustseal from "../assets/trust-seal.png";
import {
  useGeneratorQuery,
  RINGBA_STORAGE_KEYS,
  useRingba,
  useInitRingba,
} from "wecall-config-lib";
import { useNavigate } from "react-router-dom";
import { Timer } from "../App";
import QuizInitialScripts from "../script/quizInitialScript";
import { ROUTES } from "../App";

const FloatingCard = React.lazy(() => import("../floating-card"));

const routes = {
  congrats: "congratsVisited",
  income: "incomeVisited",
  medicareMedicaid: "medicareMedicaidVisited",
  lander: "landerVisited",
};

export function MedicareMedicaidQuizLander({ headline, nextPageRoute }) {
  const generatorQuery = useGeneratorQuery();
  const { storeRgbaData } = useRingba();
  const navigate = useNavigate();

  const setValues = (value) => {
    sessionStorage.setItem(RINGBA_STORAGE_KEYS.medicareMedicaid, value);
    window._rgba_tags = window._rgba_tags || [];
    window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.medicareMedicaid]: value });
  };

  const externalRedirectLink = window.domain_settings.medicareMedicAidYesLink;

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(routes.income))
      return navigate({
        pathname: "../" + ROUTES.income,
        search: generatorQuery.get(),
      });
    sessionStorage.setItem(routes.medicareMedicaid, true);
  }, []);
  return (
    <>
      <div className="headline-wrapper">
        <h1>{headline}</h1>
      </div>
      <div className="left-side">
        <div className="question">
          <h2>Are you on Medicare or Medicaid?</h2>

          <Link
            onClick={() => setValues("yes")}
            id="medicare_medicaid_yes"
            to={externalRedirectLink}
          >
            Yes
          </Link>

          <Link
            onClick={() => setValues("no")}
            id="medicare_medicaid_no"
            to={{ pathname: nextPageRoute, search: generatorQuery.get() }}
          >
            No
          </Link>
        </div>
      </div>
    </>
  );
}

export function IncomeQuizLander({ headline, nextPageRoute }) {
  const generatorQuery = useGeneratorQuery();
  const { storeRgbaData } = useRingba();

  const setValues = (value) => {
    sessionStorage.setItem(RINGBA_STORAGE_KEYS.income, value);
    // storeRgbaData(RINGBA_STORAGE_KEYS.income, value);
    window._rgba_tags = window._rgba_tags || [];
    window._rgba_tags.push({ [RINGBA_STORAGE_KEYS.income]: value });
  };

  const navigate = useNavigate();

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(routes.lander))
      return navigate({ pathname: "../", search: generatorQuery.get() });
    sessionStorage.setItem(routes.income, true);
  }, []);
  return (
    <>
      <div className="headline-wrapper">
        <h1>{headline}</h1>
      </div>
      <div className="left-side">
        <div className="question">
          <h2>Do you make less than $50k/Yr?</h2>
          <Link
            onClick={() => setValues("- - 50")}
            to={{
              pathname: "../medicare-medicaid",
              search: generatorQuery.get(),
            }}
          >
            Yes
          </Link>
          <Link
            onClick={() => setValues("50 - +")}
            to={{
              pathname: nextPageRoute,
              search: generatorQuery.get(),
            }}
          >
            No
          </Link>
        </div>
      </div>
    </>
  );
}

export function CTAQuizLander({ headline, subtitle }) {
  const generatorQuery = useGeneratorQuery();

  useEffect(() => {
    // generatorQuery.set(window.location.search);
    sessionStorage.setItem(routes.lander, true);
  }, []);
  return (
    <>
      <div className="headline-wrapper">
        <h1>{headline}</h1>
      </div>
      <div className="sub-hero-section">
        <div className="left-side">
          <h2>{subtitle}</h2>

          <div className="desktop-cta">
            <Link
              id="cta-click"
              to={{ pathname: "income", search: generatorQuery.get() }}
            >
              I Want My Card <img src={arrow} alt=""></img>
            </Link>
            <span>37 Available Slots Left, Act Now!</span>
          </div>
        </div>

        <div className="right-side">
          <img src={heroimage}></img>
        </div>
        <div className="mobile-cta">
          <Link
            id="cta-click"
            to={{ pathname: "income", search: generatorQuery.get() }}
          >
            I Want My Card <img src={arrow} alt=""></img>
          </Link>
          <span>37 Available Slots Left, Act Now!</span>
        </div>
      </div>
    </>
  );
}

export function CongratsQuizLander({ number, headline }) {
  const navigate = useNavigate();
  const { getEventId } = useEventID();

  const generatorQuery = useGeneratorQuery();

  const triggerContactEvent = () => {
    window.fbcFunc("track", "Contact", {
      eventID: getEventId(),
    });
  };

  useEffect(() => {
    generatorQuery.set(window.location.search);
    if (!sessionStorage.getItem(routes.medicareMedicaid))
      return navigate({
        pathname: "../" + ROUTES.medicareMedicaid,
        search: generatorQuery.get(),
      });
    sessionStorage.setItem(routes.congrats, true);
  }, []);

  return (
    <>
      <div className="left-side">
        <div className="congrats">
          <h2>{headline}</h2>
          <p>Space is limited. Tap the button below to call and claim.</p>
          <a
            onClick={triggerContactEvent}
            href={"tel:" + number}
            className="call_now"
          >
            {number}
          </a>
          <p>
            This program has extremely limited capacity, so call us in the next{" "}
            <Timer /> to claim your subsidy before it's given to another
            applicant in line.
          </p>
          <img src={trustseal} alt=""></img>
        </div>
      </div>
    </>
  );
}

function MobileNumberLoader({ setNumber }) {
  const testRingba = window.domain_settings.rinbaUser;

  let ringba = testRingba;
  const generator = ringba.user;
  const { number } = useInitRingba({
    ringbaKey: {
      key: testRingba.key,
      number: testRingba.number,
      user: testRingba.user,
    },
  });

  useEffect(() => {
    setNumber(number);
  }, [number]);
  return <></>;
}

export default function QuizLander({ setNumber, cityStateResponse }) {
  const location = useLocation();

  return (
    <>
      <QuizInitialScripts setNumber={setNumber} />
      {location.pathname.includes(ROUTES.income) ||
      location.pathname.includes(ROUTES.medicareMedicaid) ||
      location.pathname.includes(ROUTES.congrats) ||
      location.pathname.includes(ROUTES.healthInsurance) ||
      location.pathname.includes(ROUTES.phoneNumber) ? (
        <MobileNumberLoader setNumber={setNumber} />
      ) : undefined}
      <div className="App">
        <div className="mainWrapper">
          <header>
            <div className="container">
              <div className="row">
                <div className="logo-wrapper">
                  <a href="#">
                    <img src={logo}></img>
                  </a>
                </div>
              </div>
            </div>
          </header>
          <div className="hero-section">
            <div className="container">
              <div className="row">
                <Outlet />
                <div className="logo-strip">
                  <div className="logoitem">
                    {
                      //  <img src={newslogo1}></img>
                      //  <img src={newslogo2}></img>
                      //  <img src={newslogo3}></img>
                      //  <img src={newslogo4}></img>
                      //  <img src={newslogo5}></img>
                      //  <img src={newslogo6}></img>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section1">
            <div className="container">
              <div className="row">
                <div className="section-1-content">
                  <h1>Check for the New Subsidy for Minimum Wage Americans</h1>
                  <h2>
                    Claim up to $6400 SUBSIDY to pay for all your health care
                    needs.
                  </h2>
                  <h2>Anything left over can be used:</h2>
                  <div className="item-benefits">
                    <ul>
                      <li>
                        <div className="benefit">
                          <img src={"/assets/003-food.png"}></img>
                          <p>Groceries</p>
                        </div>
                      </li>
                      <li>
                        <div className="benefit">
                          <img src={"/assets/001-key.png"}></img>
                          <p>Rent</p>
                        </div>
                      </li>
                      <li>
                        <div className="benefit">
                          <img src={"/assets/002-gas-station.png"}></img>
                          <p>Gas</p>
                        </div>
                      </li>
                      <li>
                        <div className="benefit">
                          <img src={"/assets/004-bill.png"}></img>
                          <p>Bills</p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <h3>or whatever else you need.</h3>
                  <div className="cta-holder">
                    <Link to={"income"} id="cta-click">
                      I Want My Card <img src={arrow} alt=""></img>
                    </Link>
                    <span>37 Available Slots Left, Act Now!</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="testimonial">
            <div className="container">
              <div className="row">
                <div className="testimonial-headline">
                  <h1>Try Yourself Today!</h1>
                  <img src={testimonial} alt="" />
                </div>
                <div className="testimonial-content-holder">
                  <h3 className="h6">
                    We strive to offer you the best service, as evidenced by our
                    Trustpilot star rating.
                  </h3>
                </div>
                <div className="testimonial-list">
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <div className="testimonial-left">
                          <img src={"/assets/user-1.jpeg"}></img>
                        </div>
                        <div className="testimonial-right">
                          <div className="star-rating">
                            <img src={"/assets/star.png"} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <h3>Floyd Miles</h3>
                        <p>
                          I just received mine fast! They worked tirelessly to
                          secure the financial subsidy I needed, and their
                          dedication to customer satisfaction is unmatched.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <div className="testimonial-left">
                          <img src={"/assets/user-2.jpeg"}></img>
                        </div>
                        <div className="testimonial-right">
                          <div className="star-rating">
                            <img src={"/assets/star.png"} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <h3>Michael Anderson</h3>
                        <p>
                          Just in time when i need it the most! I can't thank US
                          Support enough for their prompt assistance. Their
                          team's commitment to me is truly commendable
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <div className="testimonial-left">
                          <img src={"/assets/user-4.jpeg"}></img>
                        </div>
                        <div className="testimonial-right">
                          <div className="star-rating">
                            <img src={"/assets/star.png"} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <h3>Olivia Martinez</h3>
                        <p>
                          I was in a financial bind, and US Support came to the
                          rescue. Their team's dedication and service to people
                          are top-notch, and I couldn't be happier with their
                          help.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-top">
                        <div className="testimonial-left">
                          <img src={"/assets/user-3.jpeg"}></img>
                        </div>
                        <div className="testimonial-right">
                          <div className="star-rating">
                            <img src={"/assets/star.png"} alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-bottom">
                        <h3>David Wilson</h3>
                        <p>
                          I'm still in awe of how quickly I received mine. This
                          helps me a lot as a minimum wage earner. Their
                          commitment to help you is evident in every step of the
                          process. Highly recommended!
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="container">
              <div className="row"></div>
            </div>
          </div>
        </div>
      </div>
      <React.Suspense fallback={<></>}>
        <FloatingCard />
      </React.Suspense>
      <LanderSocialEventTrigger />
    </>
  );
}

function LanderSocialEventTrigger() {
  const { getEventId } = useEventID();

  const viewContentKey = "PageView";
  const viewContentKey2 = "PageView2";

  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);

  useEffect(() => {
    if (window.fbcFunc) {
      window.fbcFunc("track", "PageView", { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey, "true");
    }

    if (window.tikTokEvent && params.get("utm_source") === "tiktok") {
      window.tikTokEvent.track("ViewContent", {}, { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey2, "true");
    }
  }, []);
  return <></>;
}
